import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OMatchesBlock from "gatsby-component-lib/components-legacy/organisms/o-matches-block"
import OStadiumAdBlock from "gatsby-component-lib/components-legacy/organisms/o-stadium-ad-block"

export const query = graphql`
  query CompetitionQuery($competition_uid: String, $uid: String) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    schedules: allTypo3Page(
      filter: { uid: { eq: $competition_uid }, type: { in: "schedule" } }
    ) {
      edges {
        node {
          id
          type
          website
          content
          name
          title
          full_slug
          slug
          data {
            matches {
              full_slug
              id
              kickoff
              kickoff_timezone
              language
              matchday
              matchday_title
              period
              team_away_final_score
              team_home_final_score
              team_home {
                id
                team_name
                team_initials_name
                images {
                  liveticker
                  liveticker_retina
                  medium_square
                  mini_square
                  micro_square
                  original
                }
              }
              team_away {
                id
                team_name
                team_initials_name
                images {
                  liveticker
                  liveticker_retina
                  medium_square
                  micro_square
                  mini_square
                  original
                }
              }
              competition {
                id
                slug
                title
              }
            }
            season {
              id
              slug
              title
            }
            competition {
              id
              slug
              title
            }
          }
        }
      }
    }
    competitionsBySeasons: allTypo3Page(
      filter: { type: { eq: "competitions-by-seasons" } }
    ) {
      edges {
        node {
          id
          type
          website
          language
          data {
            seasons {
              competitions {
                full_slug
                slug
                title
                id
              }
              id
              title
              full_slug
            }
          }
        }
      }
    }
  }
`

const CompetitionPage = ({ data, pageContext }) => {
  const {
    full_slug,
    tree,
    website,
    website_lang,
    website_theme,
    footerPageNodeContent,
    subnavigation,
  } = pageContext
  console.log("subnavigation", subnavigation)
  const content = JSON.parse(data.page.content)

  const { t } = useTranslation()

  if (!data.schedules.edges || !data.schedules.edges[0]) {
    return <h1>Keine Daten vorhanden</h1>
  }

  const nodeData = data.schedules.edges[0] ? data.schedules.edges[0].node : []
  const scheduleData = nodeData ? nodeData.data : {}

  const season = scheduleData.season
  const competition = scheduleData.competition
  const matches = scheduleData.matches

  // Season, Competition - Dropdowns
  const competitionsBySeason = data.competitionsBySeasons.edges[0].node.data
  let otherCompetitionsInSeason = []
  for (let comp of competitionsBySeason.seasons) {
    if (parseInt(comp.id) === parseInt(season.id)) {
      otherCompetitionsInSeason = comp
      break
    }
  }
  let seasonNavigation = {}
  for (let season of competitionsBySeason.seasons) {
    seasonNavigation[season.title] = season
  }

  let linkToAllMatches = ""
  for (const [key, value] of Object.entries(seasonNavigation)) {
    if (key === season.title) {
      linkToAllMatches = value.full_slug
    }
  }

  const second_breadcrumb = ["profis", "en"].includes(website)
    ? {
        name: t("Season"),
        full_slug: "/" + t("SeasonSlug"),
      }
    : content.parents.reverse()[1]

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    second_breadcrumb,
  ]

  const title =
    content.title +
    " " +
    season.title.replace("/", "-") +
    " " +
    (competition ? competition.title : "")

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      breadcrumbs={breadcrumbs}
      subnavigation={subnavigation}
      subnavigationDropdown2={Object.values(seasonNavigation)}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
      showAds={!content.hide_ads}
    >
      <TSeo
        website={website}
        website_theme={website_theme}
        content={content}
        full_slug={full_slug}
        title={title}
      />
      <main className="ef-content ef-content--league-table">
        <OMatchesBlock
          headline={content.headline}
          linkToAllMatches={linkToAllMatches}
          otherCompetitionsInSeason={otherCompetitionsInSeason}
          competition={competition}
          matches={matches}
          website={website}
        />
        <OStadiumAdBlock type="stadium_ad_schedule_block" />
      </main>
      <MShare
        title={title}
        website={website}
        disabled={
          content && content.disable_share_button
            ? content.disable_share_button
            : 0
        }
      />
    </OLayout>
  )
}

export default CompetitionPage
